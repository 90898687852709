import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './index.css';
import { Helmet } from 'react-helmet';

const Accueil = lazy(() => import('./components/Accueil'));
const Galerie = lazy(() => import('./components/Galerie'));
const Annonces = lazy(() => import('./components/Annonces'));
const Educatif = lazy(() => import('./components/Educatif'));
const LiveVideo = lazy(() => import('./components/LiveVideo'));
const NotFound = lazy(() => import('./components/NotFound')); // Importez le composant NotFound

function App() {
  return (
    <Router>
      <div className="app-container">
        <Helmet>
          <title>Ubercat - Tout sur les chats</title>
          <meta name="description" content="Ubercat.fr - Votre source pour tout ce qui concerne les chats. Découvrez des articles, des vidéos et des conseils pour prendre soin de votre chat." />
          <meta name="keywords" content="chats, chatons, soins pour chats, Ubercat" />
          <meta name="author" content="Ubercat.fr" />
        </Helmet>
        <Header />
        <main className="content">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/annonces" element={<Annonces />} />
              <Route path="/galerie" element={<Galerie />} />
              <Route path="/educatif" element={<Educatif />} />
              <Route path="/live-video" element={<LiveVideo />} />
              <Route path="*" element={<NotFound />} /> {/* Route de fallback */}
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;