import React from 'react';
import './Footer.css';

const Footer = React.memo(() => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Ubercat.fr - Tous droits réservés</p>
      <p>Adresse : <a href="https://www.google.fr/maps/place/8+Rue+%C3%89troite,+68000+Colmar" target="_blank" rel="noopener noreferrer">8 rue étroite, 68000 Colmar</a></p>
      <p>Téléphone : 0770326276</p>
    </footer>
  );
});

export default Footer;