import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="navbar">
      <nav>
        <h1>Ubercat.fr</h1>
        <ul>
          <Link to="/">Accueil</Link>
          <Link to="/annonces">Annonces</Link>
          <Link to="/galerie">Galerie</Link>
          <Link to="/educatif">Bonnes Pratiques</Link>
          <Link to="/live-video">Live Vidéo</Link>
        </ul>
      </nav>
    </header>
  );
};

export default Header;